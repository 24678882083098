import Vue from 'vue'
import VueRouter from 'vue-router'
import Parse from 'parse'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    //meta: { requiresAuth: true, role: ['Administrador', 'support'] }
  },
  {
    path: '/city',
    name: 'city',
    component: () => import('../views/City.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/Order.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/store/:store_id',
    name: 'store',
    component: () => import('../views/Store.vue'),
    //meta: { requiresAuth: true }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/MyOrders.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/stores/category/:category',
    name: 'storesByCategories',
    component: () => import('../views/StoresByNewCategories.vue'),
    //meta: { requiresAuth: true }
  },

  {
    path: '/pay-order/:order_id',
    name: 'payOrder',
    component: () => import('../views/PayOrder.vue'),
    //meta: { requiresAuth: true }
  },

  {
    path: '/payment-app/:order_id/:token',
    name: 'paymentOrderMobile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PayOrderMobile.vue'),
    meta: { requiresAuth: false, role: [] }
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const loggedIn = Parse.User.current();
  const roles_array = to.meta.role;
  let location = window.localStorage.getItem('festyZoneLocation')
  if ((!location || !JSON.parse(location).latitude) && to.path != '/city' && to.name != 'store' && to.name != 'login' && to.name != 'paymentOrderMobile') {
    if(from.path != '/city'){
      return next('/city');
    }
    return;
  }

  if (requiresAuth && !loggedIn) {
    return next('/login');
  }

  if (roles_array && roles_array.length > 0) {
    const params = {
      roles: roles_array
    }
    const isRoleUser = await Parse.Cloud.run("checkIfRoleIsWell", params);
    //eslint-disable-next-line
    //console.log(roles_array);
    //console.log(isRoleUser);
    if(!isRoleUser){
      Parse.User.logOut();
      return next({
        path: '/login',
        query: {
           err: '403',
        }
      });
    }

  }
  next();
})

export default router
