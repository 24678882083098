import Vue from 'vue'
import Vuex from 'vuex'
import Parse from 'parse'
//import {_} from 'vue-underscore';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    zones: [],
    zone: '',
    promos: [],
    all_promos: [],
    hotPromos: [],
    categories: [],
    loading: true,
    cart: [],
    cartModelNavigation: false,
    colsLength: 0,
    superCat: '',
    catModel: '',
    searchStoreBox: false,
    searchProductBox: false,
    products: [],
    all_products: [],
    addresses: [],
    address: ''
  },

  getters:{
    getZones: state => {
      return state.zones
    },
    getZone: state => {
      return state.zone
    },
    getPromos: state => {
      return state.promos
    },
    getAllPromos: state => {
      return state.all_promos
    },
    getHotPromos: state => {
      return state.hotPromos
    },
    getCategories: state => {
      return state.categories
    },
    getCart: state => {
      return state.cart
    },
    getCcartModelNavigation: state => {
      return state.cartModelNavigation
    },
    getColsLength: state => {
      return state.colsLength
    },
    getSuperCat: state => {
      return state.superCat
    },
    getCatModel: state => {
      return state.catModel
    },
    getSearchStoreBox: state => {
      return state.searchStoreBox
    },
    getSearchProductBox: state => {
      return state.searchProductBox
    },
    getProducts: state => {
      return state.products
    },
    getAllProducts: state => {
      return state.all_products
    },
    getAddress: state => {
      return state.address
    },
    getAddresses: state => {
      return state.addresses
    }
  },

  mutations: {
    setZones(state, value) {
      state.zones = value;
    },
    setZone(state, value) {
      state.zone = value;
    },
    setPromos(state, value) {
      state.promos = value;
    },
    setAllPromos(state, value) {
      state.all_promos = value;
    },
    setHotPromos(state, value) {
      state.hotPromos = value;
    },
    setCategories(state, value) {
      state.categories = value;
    },
    setCart(state, value) {
      state.cart = value;
    },
    pushCart(state, value) {
      state.cart.push(value);
    },
    setCcartModelNavigation(state, value) {
      state.cartModelNavigation = value;
    },
    setColsLength(state, value) {
      state.colsLength = value;
    },
    setSuperCat(state, value) {
      state.superCat = value;
    },
    setCatModel(state, value) {
      state.catModel = value;
    },
    setSearchStoreBox(state, value) {
      state.searchStoreBox = value;
    },
    setSearchProductBox(state, value) {
      state.searchProductBox = value;
    },
    setProducts(state, value) {
      state.products = value;
    },
    setAllProducts(state, value) {
      state.all_products = value;
    },
    setAddress(state, value) {
      state.address = value;
    },
    setAddresses(state, value) {
      state.addresses = value;
    },
    pushAddresses(state, value) {
      state.addresses.push(value);
    },
    
  },
  actions: {
    async getZonesWithOutLocation({ commit, state }, params) {
      try {
        
        state.zones = []
        state.promos = []
        state.loading = true
        let zones_temp = []
        let zones = await Parse.Cloud.run("getAppConfigWhitoutLocation");
        let location = JSON.parse(window.localStorage.getItem("festyZoneLocation"));
        
        state.colsLength = Object.keys(location.options).filter(key => location.options[key] === true).length;

        if (zones.length > 0) {
          zones.forEach(element => {
            zones_temp.push({
              name: element.attributes.name,
              longitude: element.attributes.center.longitude, 
              latitude: element.attributes.center.latitude,
              radius: element.attributes.radius ? element.attributes.radius : '',
              options: element.attributes.options ? element.attributes.options : '',
              country: element.attributes.country ? element.attributes.country : '',
              config: element.attributes.config ? element.attributes.config : '',
            })
          });
        }
        
        commit('setZones', zones_temp)
        commit('setZone', location)
        commit('setSuperCat', params.superCategory ? params.superCategory : 'food')

        let params_ok = {
          latitude: params.latitude ? params.latitude : location.latitude,
          longitude: params.longitude ? params.longitude : location.longitude,
          radius: params.radius ? params.radius : location.radius,
          superCategory: params.superCategory ? params.superCategory : ''
        }

        let hotPromos = await Parse.Cloud.run("getHotPromos", params_ok);
        commit('setHotPromos', hotPromos)

        let promos = await Parse.Cloud.run("getStoresList", params_ok);
        //let promo_structure = []
        //eslint-disable-next-line
        //console.log(promos);
        
        /*promo_structure = _.groupBy(_.sortBy(promos, function(promo){
          return promo
        }), function(promo) {
          return promo.attributes.store.attributes.name;
        })*/

        commit('setPromos', promos)
        commit('setAllPromos', promos)
        //let categories = promos.map(o => o.get("topProducts").map(p => p.get('category'))[0])
        let categories = promos.map(o => { 
          if (o.get("storeCategory")) {
            return o.get("storeCategory").get('name')
          }
        })
        categories = Array.from(new Set(categories))
        categories = categories.filter(Boolean).sort();
        categories.unshift('Todas')
        commit('setCategories', categories)
        commit('setCatModel', categories[0])

        if (window.localStorage.getItem("cartFestyApp")) {
          commit('setCart', JSON.parse(window.localStorage.getItem("cartFestyApp"))) 
        }
        
        state.loading = false

      } catch (error) {
        //eslint-disable-next-line
        console.log(error.message)
        if (error && error.code == 209) {
          Parse.User.logOut();
          window.location.reload();
        }
      }
    },
    
    async getHotPromos({ commit, state }, params) {
      try {
        state.hotPromos = []
        let promos = await Parse.Cloud.run("getHotPromos", params);
        commit('setHotPromos', promos)
      } catch (error) {
        //eslint-disable-next-line
        console.log(error.message)
      }
    },

    async getAddressesUser({ commit, dispatch, state }, params) {
      try {
          state.address = ''
          let addresses_temp = []
          let addresses = await Parse.Cloud.run("getAddresses", params);
          if (addresses.length > 0) {
            addresses.forEach(element => {
              addresses_temp.push({
                id: element.id,
                name: element.attributes.name,
                longitude: element.attributes.location.longitude, 
                latitude: element.attributes.location.latitude,
                address: element.attributes.address,
                city: element.attributes.city
              })
            });
          }
          commit('setAddresses', addresses_temp)
          if (addresses_temp && addresses_temp.length > 0) {
            commit('setAddress', addresses_temp[addresses_temp.length-1])
            window.localStorage.setItem("addressFestyApp", JSON.stringify(addresses_temp[addresses_temp.length-1]))
            dispatch('setZoneByAdressSelected')
          }else{
            let address_local = window.localStorage.getItem("addressFestyApp")
            if (address_local) {
              let address = JSON.parse(address_local)
              window.localStorage.setItem("addressFestyApp", JSON.stringify(address))
              commit('setAddress', address)
            }
          }
      } catch (error) {
        //eslint-disable-next-line
        console.log(error.message)
      }
    },

    async setAddressUser({ commit, state }) {
      try {
        if (window.localStorage.getItem("addressFestyApp")) {
          state.address = ''
          let address = JSON.parse(window.localStorage.getItem("addressFestyApp"))
          window.localStorage.setItem("addressFestyApp", JSON.stringify(address))
          commit('setAddress', address)
        }
      } catch (error) {
        //eslint-disable-next-line
        console.log(error.message)
      }
    },

    async setZoneByAdressSelected({ commit, state }){
      let zone = await Parse.Cloud.run("getAppConfig", {
        latitude: state.address.latitude,
        longitude: state.address.longitude
      });
      //console.log(state.address);
      //console.log(zone);
      if (zone) {
        let location = {
          name: zone.attributes.name,
          longitude: zone.attributes.center.longitude, 
          latitude: zone.attributes.center.latitude,
          radius: zone.attributes.radius ? zone.attributes.radius : '',
          options: zone.attributes.options ? zone.attributes.options : '',
          country: zone.attributes.country ? zone.attributes.country : '',
          config: zone.attributes.config ? zone.attributes.config : ''
        }
        commit('setZone', location)
        window.localStorage.setItem("festyZoneLocation", JSON.stringify(location));
      }
    }



  },
  modules: {
  }
})
