import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import Parse from 'parse'
import moment from 'moment'
import Snotify from 'vue-snotify';
import VueSwal from 'vue-swal'
import VueCurrencyFilter from 'vue-currency-filter'
import VueMeta from 'vue-meta'
import underscore from 'vue-underscore';
import VueLazyload from 'vue-lazyload'
import VueMask from 'v-mask'
import Hotjar from 'vue-hotjar'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSocialSharing from 'vue-social-sharing'

import './registerServiceWorker'

Vue.config.productionTip = false

// .env file contains those definitions.

Parse.initialize(process.env.VUE_APP_PARSE_SERVER_APPLICATION_ID, process.env.VUE_APP_JS_KEY)
Parse.serverURL = process.env.VUE_APP_PARSE_SERVER_URL

moment.locale('es');
Vue.use(require('vue-moment'), {
  moment
})

Vue.use(Snotify, {
  toast: {
    timeout: 4000
  },
  global: {
    preventDuplicates: true
  }
})

Vue.use(VueSwal)

Vue.use(VueCurrencyFilter, {
  symbol : '$',
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false
})

Vue.use(VueMeta)

Vue.use(underscore);

Vue.use(VueLazyload)

Vue.use(VueMask);

Vue.use(Hotjar, {
  id: '2015030', // Hotjar Site ID
  isProduction: true
})

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});

Vue.filter('getPriceProduct', (price, fee) => {
  let PRICE = {
     porcent : (value) => Number(price) + (Number(price)*(Number(value)/100)),
     value :  (value) => Number(price) + Number(value),
   }
   if (price && fee && fee.type && fee.value && fee.status) {
     return PRICE[fee.type](fee.value)
   }
   return price
 })

Vue.use(VueSocialSharing);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
