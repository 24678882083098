<template>
  <v-app class="myFont">
    <v-app-bar app color="#c91d20" class="d-print-none">
      <v-app-bar-nav-icon v-if="location" @click="active = !active" class="white--text"></v-app-bar-nav-icon>

      <div class="d-flex align-center mr-3" style="color: #64625e;" v-if="logo">
        <router-link to="/">
          <img
            src="https://festyapp.com/logo.png"
            alt="Logo Festy"
            width="80"
            style="vertical-align: middle;"
          />
        </router-link>
      </div>

      <v-menu
        class="d-none d-md-flex"
        offset-y
        tile
        v-if="$store.state.superCat"
        :close-on-content-click="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-none d-md-flex white--text"
            text
            v-bind="attrs"
            v-on="on"
          >
            <strong>{{ getTextCat($store.state.superCat) }}</strong> <v-icon v-if="attrs['aria-expanded'] == 'true'">mdi-chevron-up</v-icon> <v-icon v-else>mdi-chevron-down</v-icon> 
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="$store.state.zone && $store.state.zone.options.food"
            @click="initialize('food', $store.state.address, true)"
          >
            <v-list-item-title>
            <v-icon left>mdi-food</v-icon> Restaurantes
            </v-list-item-title>
          </v-list-item>

           <v-list-item
            v-if="$store.state.zone && $store.state.zone.options.drinks"
            @click="goDrinks()"
          >
            <v-list-item-title>
            <v-icon left>mdi-cup-water</v-icon> Licores
            </v-list-item-title>
          </v-list-item>

          <!--<v-list-item
            v-if="$store.state.zone && $store.state.zone.options.stores"
            @click="initialize('stores', $store.state.address)"
          >
            <v-list-item-title>
            <v-icon left>mdi-storefront</v-icon> Tiendas
            </v-list-item-title>
          </v-list-item>-->

          <v-list-item
            v-if="$store.state.zone && $store.state.zone.options.markets"
            @click="initialize('market', $store.state.address, true)"
          >
            <v-list-item-title>
            <v-icon left>mdi-storefront</v-icon> Tiendas <!-- mdi-shopping - supermercados -->
            </v-list-item-title>
          </v-list-item>

          <!--<v-list-item
            v-if="$store.state.zone && $store.state.zone.options.services"
            @click="initialize('services', $store.state.zone)"
          >
            <v-list-item-title>
            <v-icon left>mdi-bike</v-icon> Servicios
            </v-list-item-title>
          </v-list-item>-->

        </v-list>
      </v-menu>

      <v-spacer></v-spacer>

      <!--<v-btn
        v-if="authenticate"
        to="/stores"
        text
        @click.stop="active = false"
        class="d-none d-sm-flex"
      >
        <v-icon>mdi-food</v-icon>
        <span class="ml-2">Mis Comercios</span>
      </v-btn>-->
      <!--<v-row justify="end" align-content="center" v-if="location && $route.fullPath != '/city'">
        <v-col cols="10" md="4">
          <v-combobox
           v-if="$store.state.zones && $route.fullPath == '/'"
            v-model="$store.state.zone"
            :items="$store.state.zones"
            label="Ciudades"
            item-text="name"
            return-object
            outlined
            hide-details
            prepend-inner-icon="mdi-city"
            auto-select-first
            dense
            @input="reloadPage()"
          ></v-combobox>
        </v-col>

        <v-col
          v-if="$store.state.zone && $store.state.zone.options.food"
          :cols="$store.state.colsLength == 1 ? 3 : 3"
          class="d-none d-md-flex"
        >
          <v-btn
            @click="initialize('food', $store.state.zone)"
            text
            @click.stop="active = false"
            :class="`d-none d-md-flex ${tab_text == 'food' ? 'font-weight-bold' : ''}`"
            style="width: 100%;"
          >
            <v-icon>mdi-food</v-icon>
            <span class="ml-2">Restaurantes</span>
          </v-btn>
        </v-col>

        <v-col
          v-if="$store.state.zone && $store.state.zone.options.drinks"
          :cols="$store.state.colsLength == 1 ? 3 : 2"
          class="d-none d-md-flex"
          align-self="center"
        >
          <v-btn
            @click="goDrinks()"
            text
            @click.stop="active = false"
            :class="`d-none d-md-flex ${tab_text == 'drinks' ? 'font-weight-bold' : ''}`"
            style="width: 100%;"
          >
            <v-icon>mdi-glass-mug-variant</v-icon>
            <span class="ml-2">Licores</span>
          </v-btn>
        </v-col>

        <v-col
          v-if="$store.state.zone && $store.state.zone.options.stores"
          :cols="$store.state.colsLength == 1 ? 3 : 2"
          class="d-none d-md-flex"
        >
          <v-btn
            @click="initialize('stores', $store.state.zone)"
            text
            @click.stop="active = false"
            :class="`d-none d-md-flex ${tab_text == 'stores' ? 'font-weight-bold' : ''}`"
            style="width: 100%;"
          >
            <v-icon>mdi-storefront</v-icon>
            <span class="ml-2">Tiendas</span>
          </v-btn>
        </v-col>

        <v-col
          v-if="$store.state.zone && $store.state.zone.options.markets"
          :cols="$store.state.colsLength == 1 ? 3 : 3"
          class="d-none d-md-flex"
        >
          <v-btn
            @click="initialize('market', $store.state.zone)"
            text
            @click.stop="active = false"
            :class="`d-none d-md-flex ${tab_text == 'market' ? 'font-weight-bold' : ''}`"
            style="width: 100%;"
          >
            <v-icon>mdi-shopping</v-icon>
            <span class="ml-2">Supermercados</span>
          </v-btn>
        </v-col>

        <v-col
          cols="2"
          v-if="$store.state.zone && $store.state.zone.options.services"
          class="d-none d-md-flex"
        >
          <v-btn
            @click="initialize('services', $store.state.zone)"
            text
            @click.stop="active = false"
            class="d-none d-md-flex"
            style="width: 100%;"
          >
            <v-icon>mdi-bike</v-icon>
            <span class="ml-2">Servicios</span>
          </v-btn>
        </v-col>--
        <v-col
          cols="2"
          v-if="!authenticate"
          class="d-none d-md-flex"
        >
          <v-btn
            to="/login"
            text
            @click.stop="active = false"
            class="d-none d-md-flex"
            style="width: 100%;"
            color="info"
          >
            <v-icon>mdi-login</v-icon>
            <span class="ml-2">Ingresar</span>
          </v-btn>
        </v-col>
        
        <--<v-col
          v-else
          cols="2"
          class="d-none d-md-flex"
        >
          <v-btn
            @click="initialize('services', $store.state.zone)"
            text
            @click.stop="active = false"
            class="d-none d-md-flex"
            style="width: 100%;"
          >
            <v-icon>mdi-bike</v-icon>
            <span class="ml-2">Servicios</span>
          </v-btn>
        </v-col>--
      </v-row>-->

      <v-text-field
        v-if="$store.state.searchStoreBox"
        v-model="search"
        ref="search"
        placeholder="Buscar comercio"
        prepend-inner-icon="mdi-magnify"
        solo
        dense
        hide-details
        style="max-width: 290px;"
        class="mr-2 mr-1"
        background-color="grey lighten-3"
        rounded
        @keydown.enter="searchStoreByName()"
        @click:append="clearSearch()"
        type="search"
        flat
        append-icon="mdi-close"
        @focus="$vuetify.breakpoint.name == 'xs' ? logo = false : logo = true"
        @blur="logo = true"
      ></v-text-field>

      <v-text-field
        v-if="$store.state.searchProductBox"
        v-model="searchProduct"
        ref="searchProduct"
        placeholder="Buscar producto"
        prepend-inner-icon="mdi-magnify"
        solo
        dense
        hide-details
        style="max-width: 290px;"
        class="mr-2 mr-1"
        background-color="grey lighten-3"
        rounded
        @keydown.enter="searchProductByName()"
        @click:append="clearSearchProduct()"
        type="search"
        flat
        append-icon="mdi-close"
        @focus="$vuetify.breakpoint.name == 'xs' ? logo = false : logo = true"
        @blur="logo = true"
      ></v-text-field>
      
      <!--<v-select
        v-if="location && $route.fullPath != '/city' && $store.state.zones && $route.fullPath == '/'"
        v-model="$store.state.zone"
        :items="$store.state.zones"
        label="Ciudades"
        item-text="name"
        return-object
        outlined
        hide-details
        prepend-inner-icon="mdi-map-marker-radius"
        auto-select-first
        dense
        style="max-width: 200px;"
        class="d-none d-md-flex mr-1"
        @input="reloadPage()"
      ></v-select>-->

      <v-menu
        class="d-none d-md-flex"
        offset-y
        tile
        v-if="location && $route.fullPath != '/city' && $route.fullPath == '/'"
        :close-on-content-click="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-none d-md-flex white--text"
            text
            v-bind="attrs"
            v-on="on"
            @click="openAddressDialog()"
          >
            <v-icon left>mdi-map-marker-radius</v-icon><strong v-if="$store.state.address.name">{{ $store.state.address.name | str_limit(25) }}</strong> <strong v-else-if="$store.state.address.address">{{ $store.state.address.address | str_limit(25) }} </strong> <strong v-else>Buscar mi dirección</strong> <v-icon v-if="attrs['aria-expanded'] == 'true'">mdi-chevron-down</v-icon> <v-icon v-else>mdi-chevron-down</v-icon> 
          </v-btn>
        </template>
      </v-menu>

      <!--<GmapAutocomplete
        placeholder="This is a placeholder text"
        @place_changed="setPlace">
      </GmapAutocomplete>-->

      <v-btn
        v-if="!authenticate && $route.fullPath != '/city'"
        to="/login"
        text
        dark
        @click.stop="active = false"
        class="d-none d-md-flex"
        style=""
      >
        <v-icon>mdi-login</v-icon>
        <span class="ml-2">Ingresar</span>
      </v-btn> 

      <v-badge
        v-if="$store.state.cart.length && authenticate && location"
        :content="numberOfItemsCart()" 
        :value="numberOfItemsCart()"
        color="success darken-1"
        overlap
        class="mr-2 ml-1"
      >
        <v-icon @click="$store.state.cartModelNavigation = !$store.state.cartModelNavigation" large color="white">mdi-cart</v-icon>
      </v-badge>

      <!--<v-btn
        class="d-none d-sm-flex"
        href="https://festyapp.com/"
        target="_blank"
        text
      >
        <span class="mr-2" style="color: green;">FestyApp</span>
        <v-icon color="green">mdi-android</v-icon>
      </v-btn>-->
    </v-app-bar>

    <!-- $store.state. -->

    <v-navigation-drawer v-model="active" absolute temporary>
      <v-list-item v-if="authenticate">
        <v-list-item-avatar>
          <v-img :src="require('./assets/user.png')"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-if="authenticate">{{authenticate.attributes.name}}</v-list-item-title>
          <v-list-item-title v-else>Falso positivo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>

        <v-list-item
          @click="initialize('food', $store.state.address, true)"
          @click.stop="active = false"
          v-if="$store.state.zone && $store.state.zone.options.food"
        >
          <v-list-item-icon>
            <v-icon>mdi-food</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Restaurantes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="initialize('drinks', $store.state.address, true)"
          @click.stop="active = false"
          v-if="$store.state.zone && $store.state.zone.options.drinks"
        >
          <v-list-item-icon>
            <v-icon>mdi-glass-mug-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Licores</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

         <v-list-item
          @click="initialize('market', $store.state.address, true)"
          @click.stop="active = false"
          v-if="$store.state.zone && $store.state.zone.options.markets"
        >
          <v-list-item-icon>
            <v-icon>mdi-storefront</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Tiendas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!--<v-list-item
          @click="initialize('stores', $store.state.address)"
          @click.stop="active = false"
          v-if="$store.state.zone && $store.state.zone.options.stores"
        >
          <v-list-item-icon>
            <v-icon>mdi-shopping</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Tiendas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="initialize('services', $store.state.address)"
          @click.stop="active = false"
          v-if="$store.state.zone && $store.state.zone.options.services"
        >
          <v-list-item-icon>
            <v-icon>mdi-bike</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Servicios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->

        <v-divider></v-divider>

        <v-list-item
          @click="modalProfile = true"
          @click.stop="active = false"
          v-if="authenticate"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Mi perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          to="/orders"
          @click.stop="active = false"
          v-if="authenticate"
        >
          <v-list-item-icon>
            <v-icon>mdi-food-fork-drink</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Mis ordenes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
         @click="openAddressDialog()"
          @click.stop="active = false"
        >
          <v-list-item-icon>
            <v-icon>mdi-map-marker-check</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-if="authenticate">Cambiar de dirección</v-list-item-title>
            <v-list-item-title v-else>Buscar mi dirección</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          to="/city"
          @click.stop="active = false"
          v-if="!$store.state.address"
        >
          <v-list-item-icon>
            <v-icon>mdi-city</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Cambiar de ciudad</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="logOut()"
          @click.stop="active = false"
          v-if="authenticate"
        >
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          to="/login"
          @click.stop="active = false"
          v-else
        >
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Iniciar sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="$store.state.cartModelNavigation" fullscreen hide-overlay transition="dialog-bottom-transition" v-if="authenticate && $store.state.cart && $store.state.cart.length > 0">
      <v-card>
        <v-toolbar color="#f6f6f6">
          <v-btn icon @click="$store.state.cartModelNavigation = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Carrito de compras</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn small max-height="35" class="d-flex align-self-center" @click="goCreateOrder()" color="success"> <strong>Realizar Pedido</strong> <v-icon right>mdi-chevron-right</v-icon> </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card
          class="mx-auto"
          max-width="900"
          style="margin-top: 20px;"
          if
        >
        <v-toolbar flat v-if="$store.state.cart.length > 0">
          <v-toolbar-title class="black--text">Productos</v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="headline">
            Subtotal: <strong class="ml-2">{{ $store.state.cart.reduce((a, b) => { return a + (b.price + b.modifiersPrice) * b.quantity}, 0) | currency }}</strong>
          </div>
        </v-toolbar>

        <v-divider></v-divider>

          <v-card-text>
            <v-row v-for="(promo, index) in $store.state.cart" :key="index">
              <v-col cols="3" sm="2">
                <v-img
                :src="promo.imageUrl"
                :lazy-src="promo.imageUrl"
                width="100px"
                height="100px"
                class="rounded"
                ></v-img>
              </v-col>
              <v-col cols="9" sm="5" align-self="center">
                <div class="body-1">
                  <strong>{{ promo.name }}</strong>
                </div>
                <div class="body-2">
                  {{ promo.modifiersText }}
                </div>
              </v-col>
              <v-col cols="4" sm="2" style="display: flex;" align-self="center">
                   <v-btn tile small color="white" text @click="removeQuantity(promo, index)" :disabled="promo.quantity <= 1">
                      <v-icon color="grey darken-3">mdi-minus-circle-outline</v-icon>
                    </v-btn>

                    <div class="pr-2 pl-2" style="align-self: center !important;"><strong>{{ promo.quantity }}</strong></div>

                    <v-btn tile small color="white" text @click="addQuantity(promo, index)">
                      <v-icon color="success">mdi-plus-circle-outline</v-icon>
                    </v-btn>
              </v-col>
              <v-col cols="3" sm="2" align-self="center">
                <div class="subtitle-2">
                  {{ (promo.price + promo.modifiersPrice) * promo.quantity | currency }}
                </div>
              </v-col>
              <v-col cols="1" sm="1" align-self="center" class="pa-0 ma-0">
                <v-icon @click="removePromoCart(index)">mdi-delete</v-icon>
              </v-col>
              <v-col cols="12" class="pa-0 ma-0" v-if="index < $store.state.cart.length-1">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        
      </v-card>
    </v-dialog>

    <!-- Dialogo Profile -->
    <v-dialog
      v-model="modalProfile"
      width="600"
      scrollable
    >

      <v-card>
        <v-card-title
          class="title grey lighten-3"
          primary-title
        >
          <span class="text-capitalize"> Perfil de usuario </span>
          <v-spacer></v-spacer>
         
        </v-card-title>

        <v-card-text>
          <v-divider class="mb-2"></v-divider>
            <v-row dense>
                <v-col cols="12" class="text-center">
                  <v-avatar
                    size="100"
                    color="grey"
                  >
                    <img v-if="userProfile.photoUrl" :src="userProfile.photoUrl" alt="alt">
                    <v-icon v-else >mdi-account</v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="12">
                   <v-text-field
                      v-model="userProfile.name"
                      outlined
                      label="Nombre"
                      prepend-inner-icon="mdi-account"
                      hide-details
                      disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                   <v-text-field
                      v-model="userProfile.email"
                      outlined
                      label="Email"
                      prepend-inner-icon="mdi-email"
                      hide-details
                      disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                   <v-text-field
                      v-model="userProfile.phone"
                      outlined
                      label="Teléfono"
                      prepend-inner-icon="mdi-cellphone"
                      hide-details
                      disabled
                    ></v-text-field>
                </v-col>
            </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="modalProfile=false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
          <v-spacer></v-spacer>
           <v-btn
            color="success"
            outlined
            disabled
          >
           <v-icon left>mdi-content-save-outline</v-icon> Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo Profile -->

    <!-- Dialogo addressesDaialogOut -->
    <v-dialog
      v-model="addressesDaialogOut"
      width="600"
      scrollable
    >

      <v-card>
        <v-card-title primary-title>
          <v-spacer></v-spacer>
          <v-icon @click="addressesDaialogOut = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pb-5">
          <h2>Ingrese la dirección donde desea recibir su pedido</h2>
          <div class="pb-2"></div>
          <p>Econtraremos comercios cerca a su dirección</p>
          <GmapAutocomplete
            class="v-input theme--light v-text-field v-text-field--single-line v-text-field--solo v-text-field--is-booted v-text-field--enclosed v-text-field--outlined pa-4"
            style="width:100%; border-style:solid;"
            placeholder="Ingrese su dirección"
            @place_changed="setPlace"
            :options="autocompleteOptions">
          </GmapAutocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="info"
            outlined
            @click="searchStores()"
            :disabled="!$store.state.address ? true : false"
          >
           <v-icon left>mdi-shopping-search</v-icon> Ver comercios
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo addressesDaialogOut -->

    <!-- Dialogo newAddressesDaialog -->
    <v-dialog
      v-model="newAddressesDaialog"
      width="480"
      scrollable
      persistent
    >

      <v-card>
        <v-card-title primary-title>
          Nueva dirección
          <v-spacer></v-spacer>
          <v-icon @click="newAddressesDaialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pb-5">
          <v-row class="pa-2">

            <v-col cols="12" class="pa-0 pb-2">
              <v-select
                v-if="zonesByCountry"
                v-model="newDirection.city"
                :items="zonesByCountry"
                label="Ciudad (requerido)"
                item-text="name"
                return-object
                outlined
                hide-details
                auto-select-first
                dense
                @change="changeCity()"
              ></v-select>
            </v-col>

            <v-col cols="12" class="pa-0 pb-2">
              
              <GmapAutocomplete
                class="v-input theme--light v-text-field v-text-field--single-line v-text-field--solo v-text-field--is-booted v-text-field--enclosed v-text-field--outlined pa-2"
                style="width:100%; border-style:solid;"
                placeholder="Dirección (Seleccione una dirección de la lista)"
                @place_changed="getLocationAddressNew"
                :options="autocompleteOptions"
                selectFirstOnEnter>
              </GmapAutocomplete>

              <span class="caption font-weight-bold" v-if="address_temp">Sugerencia: {{address_temp}}</span>

            </v-col>

            <v-col cols="12" class="pa-0">
              <v-text-field
                v-model="newDirection.barrio"
                label="Barrio (requerido)"
                outlined
                hint="Importante para llegar más rapido"
                persistent-hint
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="pa-0">
              <v-text-field
                v-model="newDirection.complement"
                label="Apt, torre, piso x, edificio"
                outlined
                hint=""
                persistent-hint
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="pa-0">
              <v-text-field
                v-model="newDirection.name"
                label="Nombre (opcional)"
                outlined
                hint="Ejemplo: Mi casa, Mi oficina, Empresa"
                persistent-hint
                dense
              ></v-text-field>
            </v-col>
            
            <v-col cols="12" class="pa-0 mt-2 text-center">
              <p class="pa-0 ma-0 caption">Puede arrastrar el marcador para ajustar su dirección</p>
              <GmapMap :center="center" :zoom="15" style="width: 100%; height: 200px" :options="{zoomControl: true, mapTypeControl: false, scaleControl: false, streetViewControl: false, rotateControl: false, fullscreenControl: false, disableDefaultUi: false,}">
                <GmapMarker :position="marker.position" :clickable="true" :draggable="true" @click="center=marker.position" @dragend="dragMarkerAddress"></GmapMarker>
              </GmapMap>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="info"
            outlined
            @click="saveAnddress()"
            :loading="loadingAddress"
          >
           <v-icon left>mdi-content-save</v-icon> Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo newAddressesDaialog -->

    <v-main class="pa-0" style="background-color: #f1f1f1;">
      <div class="d-print-none" style="margin-top: 64px;"></div>
      <v-progress-linear v-if="loading" color="primary" indeterminate reverse></v-progress-linear>
      <router-view :key="$route.fullPath" @opendialogaddressnew="openAddressDialogNew"></router-view>
    </v-main>

    <v-footer app class="font-weight-medium pb-0 pt-0 d-none d-md-flex">
      <v-col class="text-center pt-1 pb-1 body-2" cols="12">
        Copyright © {{ new Date().getFullYear() }}
        <a class="red--text" href="https://festyapp.com" style="text-decoration: none;">
          <strong> Festy.</strong>
        </a> 

        <a href="https://festyapp.app.link/ICH1KUMIf5" class="ml-1 mr-1 mt-2">
            <img :src="require('./assets/app_store.svg')" alt="" style="width: 90px; max-width: 200px; vertical-align: middle;">
          </a>
          <a href="https://festyapp.app.link/znRe7HEIf5">
            <img :src="require('./assets/google-play-badge-ok.png')" alt="" style="max-width: 100px; vertical-align: middle;">
          </a>
        
        <!--Todos los derechos reservados.-->
       
      </v-col>
      <v-btn
        v-if="authenticate"
        dark
        absolute
        top
        right
        fab
        small
        color="grey darken-4"
        to="/orders"
      >
        <v-icon>mdi-food-fork-drink</v-icon>
      </v-btn>
    </v-footer>

    <v-footer app class="d-flex d-md-none pb-0 pt-0">
      <v-col class="text-center pt-1 pb-0 caption" cols="12">
       <!-- Copyright © {{ new Date().getFullYear() }}
        <a class="red--text" href="https://festyapp.com" style="text-decoration: none;">
          <strong> Festy.</strong>
        </a> Todos los derechos reservados.-->
        <div class="pb-1">
          <a href="https://festyapp.app.link/ICH1KUMIf5" class="ml-1 mr-1 mt-2">
            <img :src="require('./assets/app_store.svg')" alt="" style="width: 90px; max-width: 200px; vertical-align: middle;">
          </a>
          <a href="https://festyapp.app.link/znRe7HEIf5">
            <img :src="require('./assets/google-play-badge-ok.png')" alt="" style="max-width: 100px; vertical-align: middle;">
          </a>
        </div>
      </v-col>
      <!--<v-btn
        v-if="authenticate"
        dark
        absolute
        top
        right
        fab
        small
        color="grey darken-4"
        to="/orders"
      >
        <v-icon>mdi-food-fork-drink</v-icon>
      </v-btn>-->
      <v-btn
        dark
        absolute
        top
        right
        fab
        x-small
        color="grey darken-4"
        @click="openAddressDialog()"
      >
        <v-icon>mdi-map-marker-check</v-icon>
      </v-btn>
    </v-footer>

    <vue-snotify></vue-snotify>

    <audio id="alert_audio" controls style="display:none;">
      <source :src="require('./assets/alert.mp3')" type="audio/mpeg" />Your browser does not support the audio element.
    </audio>

    <!--<v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
      Actualización disponible
      <v-btn text @click="refreshApp()">
        Refrescar
      </v-btn>
    </v-snackbar>-->

    <v-bottom-sheet v-model="addressesDaialog">
      <v-list>
        <v-subheader>
          Direcciones
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            outlined
            @click="openAddAddressDialog()"
            small
          >
            <v-icon left>mdi-map-marker-plus</v-icon> Agregar dirección
          </v-btn>
        </v-subheader>
        <v-list-item
          v-for="item in this.$store.state.addresses"
          :key="item.id"
          @click="setAddress(item)"
        >
          <v-list-item-avatar class="ma-0">
            <v-avatar
              size="32px"
              tile
            >
              <v-icon color="success">mdi-map-marker-circle</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
            <v-list-item-title>{{ item.address }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
          <v-btn icon @click.stop="removeAddress(item)" color="error">
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>

  </v-app>
</template>

<script>
import Parse from "parse";
import update from './mixins/update.js'
import stringSimilarity from 'string-similarity'
import axios from 'axios'

export default {
  name: "App",

  components: {},

  mixins: [update],

  data: () => ({
    authenticate: Parse.User.current(),
    active: false,
    loading: false,
    userProfile: {},
    modalProfile: false,
    location: window.localStorage.getItem("festyZoneLocation") ? JSON.parse(window.localStorage.getItem("festyZoneLocation")) : '',
    tab_text: '',
    cols_menu: '',
    search: '',
    searchProduct: '',
    logo: true,
    addressesDaialog: false,
    addressesDaialogOut: false,
    newAddressesDaialog: false,
    autocompleteOptions: {
      componentRestrictions: {
        country: [
          'co',
        ],
      },
      types: ["address"]
    },
    marker: {
      position: {
        lat: 0,
        lng: 0
      }
    },
    center: {
      lat: 0,
      lng: 0
    },

    newDirection: {
      name: "",
      address: "",
      barrio: "",
      complement: '',
      city: ''
    },
    address_temp: '',
    loadingAddress: false,
    zonesByCountry: [],
    country: ''
  }),

  mounted() {
    this.initZones()
    if (this.authenticate) {
      this.$store
          .dispatch("getAddressesUser", {})
          .finally(() => {
            if (this.$store.state.address) {
              this.initialize("food", this.$store.state.address)
            }else{
              this.initialize("food", {})
              //this.newAddressesDaialog = true
            }
          });
      this.userProfile = {
        name: this.authenticate.attributes.name,
        email: this.authenticate.attributes.email,
        photoUrl: this.authenticate.attributes.photoUrl,
        phone: this.authenticate.attributes.phone
      }
    }else{
      this.$store
          .dispatch("setAddressUser")
          .finally(() => {
            //console.log(this.$store.state.address);
            if (this.$store.state.address) {
              this.initialize("food", this.$store.state.address)
            }else{
              //console.log(this.$route.fullPath);
              this.initialize("food", {})
            }
      });
    }
  },

  methods: {
    async initZones(){
      try {
                let zones = await Parse.Cloud.run("getAppConfigWhitoutLocation");
                let zones_temp = []
                if (zones.length > 0) {
                    zones.forEach(element => {
                        zones_temp.push({
                          name: element.attributes.name,
                          longitude: element.attributes.center.longitude, 
                          latitude: element.attributes.center.latitude,
                          radius: element.attributes.radius ? element.attributes.radius : '',
                          options: element.attributes.options ? element.attributes.options : '',
                          country: element.attributes.country ? element.attributes.country : '',
                          config: element.attributes.config ? element.attributes.config : '',
                        })
                    });
                }
                this.$store.commit("setZones", zones_temp);
                //eslint-disable-next-line
                //console.log(zones);
             
            } catch (error) {
                if (error && error.code == 209) {
                  Parse.User.logOut();
                  window.location.reload();
                }
                this.$snotify.error(`${error}` , 'Zonas', {
                    timeout: 4000,
                    preventDuplicates: true
                });
            }
    },
    logOut() {
      this.$swal({
            title: "Cerrar sesión",
            text: "¿Estás seguro que deseas cerrar la sesión?",
            icon: "warning",
            buttons: ["Cancelar", 'Estoy seguro'],
            dangerMode: true,
          })
          .then(async (willDelivered) => {
            if (willDelivered) {

              /* eslint-disable */
              OneSignal.push(function() {
                OneSignal.removeExternalUserId();
              });
                            
               Parse.User.logOut();
               window.localStorage.setItem("addressFestyApp", '')
               window.location.reload();

            } else {
              //this.cancelOrderDialog = false
              //this.$swal("Orden no cancelada", 'la orden no se canceló', 'info');
            }
          });
    },

    initialize(tab, address = null, redirect = false) {
      if (this.$route && this.$route.fullPath != '/' && redirect) {
        this.$router.push('/')
      }
      this.tab_text = tab;
      this.loading = true;
      let params = {
        latitude: address ? address.latitude : "",
        longitude: address ? address.longitude : "",
        //radius: zone ? zone.radio : "",
        superCategory: tab
      };
      //console.log(params);
      this.$store
        .dispatch("getZonesWithOutLocation", params)
        .finally(() => {
          this.loading = false
          if (this.$store.state.zone) {
            this.marker.position = {
               lat: Number(this.$store.state.zone.latitude),
               lng: Number(this.$store.state.zone.longitude),
            }
            this.center = {
               lat: Number(this.$store.state.zone.latitude),
               lng: Number(this.$store.state.zone.longitude),
            }
          }
        });

      /* eslint-disable */
      //console.log(this.$router.history.current.path);
      if (this.$router.history.current.path != '/') {
        //this.$router.push('/')
      }
    },

    goDrinks(){
      if (window.localStorage.getItem('festyAppIsAdult') && this.$store.state.address) {
        this.initialize('drinks', this.$store.state.address, true)
        return;
      }

      this.$swal({
          title: "¿Eres mayor de edad?",
          text: "El exceso de alcohol es perjudicial para la salud. Ley 30 de 1986. Prohíbase el expendio de bebidas embriagantes a menores de edad y mujeres embarazadas. Ley 124 de 1994.",
          icon: "info",
          buttons: ['No', 'Soy mayor de edad'],
          dangerMode: true,
        })
        .then((ok) => {
          if (ok) {
            window.localStorage.setItem('festyAppIsAdult', true)
            this.initialize('drinks', this.$store.state.address)
          }else{
            window.localStorage.setItem('festyAppIsAdult', '')
            return;
          }
      });   
    },

    addQuantity(promo, index){
      promo.quantity = promo.quantity+1
      this.$store.state.cart.splice(index, 1, promo);
      window.localStorage.setItem("cartFestyApp", JSON.stringify(this.$store.state.cart));
    },

    removeQuantity(promo, index){
      if (promo.quantity > 1) {
        promo.quantity = promo.quantity-1
        this.$store.state.cart.splice(index, 1, promo);
        window.localStorage.setItem("cartFestyApp", JSON.stringify(this.$store.state.cart));
      }
    },

    removePromoCart(index){
      this.$store.state.cart.splice(index, 1);
      window.localStorage.setItem("cartFestyApp", JSON.stringify(this.$store.state.cart));

      if (this.$store.state.cart.length === 0){
        window.localStorage.setItem("storePaymentsMethodsFestyApp", JSON.stringify([]));
        window.localStorage.setItem("storePaymentsHasCardReader", '');
        this.$store.state.cartModelNavigation = false
      }
    },

    goCreateOrder(){
      /*this.$snotify.info('En pocos días podrás pedir a tus comercioes favoritos' , 'Muy pronto!!', {
        timeout: 4000,
        preventDuplicates: true
      });*/
      this.$store.state.cartModelNavigation = false
     if (this.$router.history.current.path != '/order') {
        this.$router.push('/order')
      }
    },

    numberOfItemsCart(){
      //console.log(this.$store.state.cart.reduce((a, b) => { return a + b.quantity }, 0));
      return this.$store.state.cart.length > 0 ? this.$store.state.cart.reduce((a, b) => { return a + b.quantity }, 0) : 0;
    },

    reloadPage(){
      window.localStorage.setItem("festyZoneLocation", JSON.stringify(this.$store.state.zone));
      window.location.href = '/';
    },

    getTextCat(cat){
      switch (cat) {
        case 'food': return 'Restaurantes'
        case 'drinks': return 'Licores'
        case 'market': return 'Tiendas' // Cambiar por supermercados cuando sea requerido
        case 'stores': return 'Tiendas'
        case 'services': return 'Servicios'
        default: return ''
      }
    },
    searchStoreByName(){
      if (this.search) {
        //this.$store.commit("setPromos", this.$store.state.all_promos);
        let criteria = this.search.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1").normalize()
        let promos = this.$store.state.all_promos.filter(store => {
             let name_store_normalize = store.attributes.name.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1").normalize()
             if (store.attributes.name && stringSimilarity.compareTwoStrings(name_store_normalize, criteria) > 0.1) {
               return store
             }
        }).sort((a,b)=>{
            return  stringSimilarity.compareTwoStrings(b.attributes.name.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1").normalize(), criteria) - stringSimilarity.compareTwoStrings(a.attributes.name.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1").normalize(), criteria)
        });
        this.$store.commit("setPromos", promos)
      }else{
        this.$store.commit("setPromos", this.$store.state.all_promos)
      }
      this.$refs.search.blur()
    },
    clearSearch(){
      this.search = ''
      this.$store.commit("setPromos", this.$store.state.all_promos)
      this.$refs.search.blur()
    },

    searchProductByName(){
      //this.$store.commit("setProducts", this.$store.state.all_products)
      if (this.searchProduct) {
        let criteria = this.searchProduct.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1").normalize()
        let promos = []
        for (let index = 0; index < this.$store.state.all_products.length; index++) {
          let sumSimilatiry = 0
          const category = this.$store.state.all_products[index];
          let products = category.attributes.products.filter(product => {
            let name_store_normalize = product.attributes.name.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1").normalize()
            let similarity = stringSimilarity.compareTwoStrings(name_store_normalize, criteria)
            sumSimilatiry += similarity
            if (product.attributes.name && similarity > 0.1) {
              return product
            }
          }).sort((a,b)=>{
            return  stringSimilarity.compareTwoStrings(b.attributes.name.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1").normalize(), criteria) - stringSimilarity.compareTwoStrings(a.attributes.name.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1").normalize(), criteria)
          });
          
          let length = promos.push(category.toJSON())
          promos[length-1].attributes = {
            products: products
          }
          promos[length-1].similarity = sumSimilatiry
        }
        let orderPromos = promos.sort( (a, b) => b.similarity - a.similarity)
        this.$store.commit("setProducts", orderPromos)
      }
      this.$refs.searchProduct.blur()
    },

    clearSearchProduct(){
      this.searchProduct = ''
      this.$store.commit("setProducts", this.$store.state.all_products)
      this.$refs.searchProduct.blur()
    },

    setPlace(address){
      if (address && address.geometry) {
        let newAdress = {
          id: '',
          name: address.name,
          latitude: address.geometry.location.lat(),
          longitude: address.geometry.location.lng(), 
          address: address.formatted_address,
          city: address.vicinity
        }
        //console.log(newAdress);
        this.$store.commit("setAddress", newAdress)
        this.$store.commit('setAddresses', [newAdress])
        window.localStorage.setItem("addressFestyApp", JSON.stringify(newAdress))
        if (this.$store.state.address && this.$store.state.address.latitude && this.$store.state.address.longitude) {
          this.initialize(this.$store.state.superCat, this.$store.state.address)
          this.$store.dispatch('setZoneByAdressSelected')
          //this.addressesDaialogOut = false
        }
      }
    },

    getLocationAddressNew(address){
      this.marker.position = {
        lat: address.geometry.location.lat(),
        lng: address.geometry.location.lng()
      }
      this.center = {
        lat: address.geometry.location.lat(),
        lng: address.geometry.location.lng()
      }
      this.newDirection.address = address.formatted_address
    },

    searchStores(){
      this.addressesDaialogOut = false
    },

    setAddress(item){
      this.$store.commit("setAddress", item)
      this.initialize(this.$store.state.superCat, item)
      window.localStorage.setItem("addressFestyApp", JSON.stringify(item))
      this.$store.dispatch('setZoneByAdressSelected')
      this.addressesDaialog = false
    },

    async openAddressDialog(){
      if (this.authenticate) {
        this.addressesDaialog = true //getAddresses
        //console.log(this.$store.state.address);
      }else{
        this.autocompleteOptions.componentRestrictions.country = [`${this.location ? this.location.country : 'CO'}`]
        this.addressesDaialogOut = true
      }
    },

    openAddressDialogNew(){
      //this.newAddressesDaialog = true
      this.openAddressDialog()
    },

    dragMarkerAddress(event){
      if (event && event.latLng) {
        this.marker.position = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        }
        this.decodeLatLong(this.marker.position)
      }
    },

    decodeLatLong (position) {
      this.$gmapApiPromiseLazy().then(() => {
        // eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ location: { lat: position.lat, lng: position.lng } }, (results, status) => {
          if (status === 'OK') {
            this.address_temp = results[0].formatted_address
          }
        })
      })
    },

    async saveAnddress(){
      if (!this.newDirection || !this.newDirection.address || !this.marker || !this.marker.position.lat || !this.marker.position.lng) {
        this.$snotify.error(`Seleccione una dirección de la lista` , 'Dirección', { 
          timeout: 4000,
          preventDuplicates: true
        });
        return;
      }

      if (!this.newDirection.city || !this.newDirection.barrio) {
        this.$snotify.error(`La ciudad o barrio son campos requeridos` , 'Ciudad y barrio', { 
          timeout: 4000,
          preventDuplicates: true
        });
        return;
      }

      this.loadingAddress = true
      try {
        let params = {
          name: this.newDirection.name,
          address: `${this.newDirection.address}${this.newDirection.barrio ? ', '+this.newDirection.barrio: ''}${this.newDirection.complement ? ', '+this.newDirection.complement: ''}`,
          city: this.newDirection.city.name,
          latitude: this.marker.position.lat,
          longitude: this.marker.position.lng
        }
        
        let address = await Parse.Cloud.run("saveAddress", params);
        if (address) {
          let newAddress = {
            id: address.id,
            name: address.attributes.name,
            longitude: address.attributes.location.longitude, 
            latitude: address.attributes.location.latitude,
            address: address.attributes.address,
            city: address.attributes.city
          }
          this.$store.commit('pushAddresses', newAddress)
          if (!this.$store.state.address) {
            this.$store.commit('setAddress', newAddress)
            this.initialize("food", this.$store.state.address)
          }
          this.newAddressesDaialog = false
          this.$snotify.success(`Se ha guardado la dirección` , 'Nueva dirección', {
            timeout: 4000,
            preventDuplicates: true
          });
        }
        
        this.loadingAddress = false
        //eslint-disable-next-line
        //console.log(params);

        this.newDirection = {
          name: '',
          address: '',
          barrio: '',
          complement: '',
          city: ''
        }
             
      } catch (error) {
        this.$snotify.error(`${error}` , 'Nueva dirección', {
          timeout: 4000,
          preventDuplicates: true
        });
        this.loadingAddress = false
      }

    },

    async removeAddress(item){
      this.$swal({
            title: "Eliminar dirección",
            text: "¿Estás seguro que deseas eliminar la dirección?",
            icon: "warning",
            buttons: ["Cancelar", 'Estoy seguro'],
            dangerMode: true,
          })
          .then(async (willDelivered) => {
            if (willDelivered) {
              try {
                this.loadingAddress = true
        
                let params = {
                  id: item.id,
                }
                
                let addresses = await Parse.Cloud.run("deleteAddress", params);
                if (addresses) {
                  let addresses_temp = []
                  addresses.forEach(element => {
                    addresses_temp.push({
                      id: element.id,
                      name: element.attributes.name,
                      longitude: element.attributes.location.longitude, 
                      latitude: element.attributes.location.latitude,
                      address: element.attributes.address,
                      city: element.attributes.city
                    })
                  });
                  this.$store.commit('setAddresses', addresses_temp)
                }
                this.loadingAddress = false
                
                this.$snotify.success(`Se ha eliminado la dirección` , 'Eliminar dirección', {
                  timeout: 4000,
                  preventDuplicates: true
                });
        
                if (this.$store.state.address.id == item.id && this.$store.state.addresses.length > 0) {
                  this.$store.commit("setAddress", this.$store.state.addresses[this.$store.state.addresses.length-1])
                  this.initialize(this.$store.state.superCat, this.$store.state.address)
                  this.$store.dispatch('setZoneByAdressSelected')
                }else if(this.$store.state.addresses.length <= 0){
                  this.$store.commit("setAddress", '')
                  window.localStorage.setItem("addressFestyApp", '')
                  window.location.reload();
                }
                     
              } catch (error) {
                this.$snotify.error(`${error}` , 'Eliminar dirección', {
                  timeout: 4000,
                  preventDuplicates: true
                });
                this.loadingAddress = false
              }
            } else {
              //this.cancelOrderDialog = false
              //this.$swal("Orden no cancelada", 'la orden no se canceló', 'info');
            }
          });
    },

    async openAddAddressDialog(){

      try {
          let response = await axios.get('https://ipinfo.io/json?token=c32e6b36c5ab34')
          if (response && response.data) {
              this.country = response.data.country
          }else{
              this.country = "CO"
          }
      } catch (error) {
          this.country = "CO"
      }

      try {
        if (this.zonesByCountry.length <= 0) {
          this.zonesByCountry = this.$store.state.zones.filter(zone => zone.country == this.country);
          this.autocompleteOptions.componentRestrictions.country = [`${this.country}`]
          if (this.country != 'CO') {
            this.center = {
              lat: 17.718703,
              lng: -64.805565
            }
            this.marker.position = {
              lat: 17.718703,
              lng: -64.805565
            }
          }
        }
        
        this.newAddressesDaialog = true
      } catch (error) {
        this.zonesByCountry = this.$store.state.zones.filter(zone => zone.country == 'CO');
        console.log(error);
      }
    },

    changeCity(){
      if (this.newDirection.city && !this.newDirection.address) {
        this.center = {
          lat: this.newDirection.city.latitude,
          lng: this.newDirection.city.longitude
        }
        this.marker.position = {
          lat: this.newDirection.city.latitude,
          lng: this.newDirection.city.longitude
        }
      }
    }
    
    
  }
};
</script>

<style lang="scss">
@import "~vue-snotify/styles/material";
</style>

<style lang="css">
.myFont {
  font-family: "Quicksand" !important;
}

.v-application {
   font-family: "Quicksand" !important;
 }

.swal-modal {
  font-family: Helvetica Neue, Arial, sans-serif;
}
</style>